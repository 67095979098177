import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from "react-router";
import {Redirect} from "react-router-dom"
import Sheet from '../lib/Sheet'
import { colors } from '../lib/globals'
import {Buttons, PrintButtons} from './SummaryComponents/Buttons'
import PrimaryInfo from './SummaryComponents/PrimaryInfo'
import Checklist from './SummaryComponents/Checklist'

const Title = styled.div({
  fontSize: 30,
  fontWeight: 700,
})


const StyledSheet = styled(Sheet)({
  marginBottom: '1.5em',
  '@media print': { boxShadow: 'none !important' },
})

const MappingField = styled.div({
  backgroundColor: colors.textAreaBG,
  borderRadius: 15,
  padding: 20,
  margin: '0 0 15px 0px',
})

const Separator = styled.div({
  width: '100%',
  margin: '15px 0',
  borderBottom: '1px dashed #ccc',
})

const BorderAround = styled.div({
  border: '1px solid #ccc',
  borderRadius: 4,
  marginBottom: 15,
})

const InnerBorder = styled.div({
  borderRadius: 4,
  padding: 10,
})

const Table = styled.table({
  border: '1px solid grey',
  borderRadius: 4,
  width: '100%',
  marginBottom: '1.5em',
})
const Td = styled.td({
  border: 'none',
  verticalAlign: 'middle',
})

const HrLine = styled.hr({
  border: 'solid 1px',
  borderRadius: '2px',
  margin: '30px 0'
})

class Summary extends Component {
  constructor(props) {
    super()

  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  isSelected(data) {
    return data.map(e => {
      return e.selected
    }).includes(true);
  }

  render() {
    if(!this.props.location.state) {
      return <Redirect to={'/start'} />
    }

    const summary = this.props.location.state.data.map((situation, index)=> {
      if(!situation.customCards[0].changed) {
       return null
      }
      return (
        <div key={index}>
          <h2
          css={{
            fontWeight: 600,
            fontSize: 25,
          }}
          >{situation.title}</h2>
          {
            situation.customCards.map((card, cardIndex) =>{
              if(this.isSelected(card.atgarder)) {
                return (
                  <div key={cardIndex}>
                    <MappingField>
                      <div>
                        <p css={{fontWeight: 600}}>Beteendehinder</p>
                        <p css={{marginBottom: 2 }}>{card.beteendehinder}</p>
                        <Separator />
                        <p css={{fontWeight: 600}}>Önskat beteende</p>
                        <p css={{marginBottom: 2 }}>{card.onskelage}</p>
                      </div>
                    </MappingField>
                    <HrLine/>
                  </div>
                )
              }
              return null
            })
          }
          {
            (situation.selected > 0)
          }
        </div>
      )
    })

    const checklist = this.props.location.state.data.map((situation, index)=> {
      if(situation.selected>0) {
        return (
          <BorderAround key={index}>
          <InnerBorder>
            <h2
            css={{
              fontWeight: 700,
              margin: 10,
              fontSize: 25,
              lineHeight: '25px',
            }}
            >{situation.title}</h2>
            {
              situation.cards.cards.map((card, cardIndex) => {
                if(this.isSelected(card.atgarder)) {
                  return (
                    <Checklist data={card} key={cardIndex}/>
                  )
                }
                return null
              })

            }
            {
              situation.customCards.map((card, cardIndex) =>(
                <Checklist data={card} key={cardIndex}/>
              ))
            }
          </InnerBorder>
          </BorderAround>
        )
      }
      return null
    })

    return (
      <div >
        <StyledSheet ref={el => (this.componentRef1 = el)}>
          <div css={{ display: 'flex', marginBottom: '15px' }}>
            <Buttons data={this.props.location.state} />
          </div>
          <div css={{ display: 'flex' }}>
            <Title>Egen beskrivning</Title>
            <PrintButtons componentRef2={()=> this.componentRef2} componentRef1={()=> this.componentRef1}/>
          </div>
          <PrimaryInfo data={this.props.location.state.personalInfo}/>
          <div>
            <div>
              {summary}
            </div>
          </div>
        </StyledSheet>
        <StyledSheet ref={el => (this.componentRef2 = el)}>
            <Title>Checklista för extra anpassningar</Title>
            <Table css={{marginTop: '1em'}}>
              <tbody>
                <tr>
                  <Td css={{borderBottom: '1px solid grey', borderRight: '1px solid grey'}}>Datum: {this.props.location.state.personalInfo.date}</Td>
                  <Td css={{borderBottom: '1px solid grey'}}>Skola: {this.props.location.state.personalInfo.school}</Td>
                </tr>
                <tr>
                  <Td css={{borderRight: '1px solid grey'}}>Lärare: {this.props.location.state.personalInfo.teacher}</Td>
                  <Td>Elev: {this.props.location.state.personalInfo.student}</Td>
                </tr>
              </tbody>
            </Table>
            {checklist}
        </StyledSheet>
      </div>
    )
  }
}

export default withRouter(Summary)

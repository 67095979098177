import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Button from '../lib/Button'
import Sheet from '../lib/Sheet'

const Title = styled.div({
  fontSize: '2em',
  fontWeight: 700,
  textAlign: 'center'
})

const StyledSheet = styled(Sheet)({
  marginBottom: 100,
  '@media print': { boxShadow: 'none !important' },
})

const Wrapper = styled.div({
  backgroundColor: '#faf8f7',
  borderRadius: 15,
  display: 'flex',
  flexWrap: 'wrap',
  margin: '25px 0 40px',
})
const PrimaryCell = styled.div({
  flexGrow: 1,
  width: '50%',
  height: 80,
  border: '0px dashed #ddd',
  padding: 10,
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
})
const CellInput = styled.input({
  borderStyle: 'none',
  width: '95%',
  height: '70%',
  borderRadius: '8px',
  paddingLeft: '.5em',
  marginLeft: '.5em',
  '::placeholder':{
    fontSize: '0.9em',
  }
})
const Paragraph = styled.p({
  marginBottom: '.5em',
  marginLeft: '1em'
})

class PersonalInfo extends Component {
  constructor(props) {
    super()
    this.state = {
      inputData: {}
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  setInput(key, val) {
    this.setState({
      inputData: {...this.state.inputData, [key]: val}
    })
  }

  render() {
    return (
      <StyledSheet>
          <Title>Fyll i personlig information</Title>
        <Wrapper>
          <PrimaryCell css={{ borderBottomWidth: 1, borderRightWidth: 1 }}>
            <Paragraph>Datum</Paragraph>
            <CellInput placeholder="Fyll i datum" name="date" onInput={e => this.setInput(e.target.name, e.target.value)}/>
          </PrimaryCell>
          <PrimaryCell css={{ borderBottomWidth: 1 }}>
            <Paragraph>Skola</Paragraph>
            <CellInput placeholder="Fyll i namn på skola" name="school" onInput={e => this.setInput(e.target.name, e.target.value)}/>
          </PrimaryCell>
          <PrimaryCell css={{ borderRightWidth: 1 }}>
            <Paragraph>Lärare</Paragraph>
            <CellInput placeholder="Fyll i namn på lärare" name="teacher" onInput={e => this.setInput(e.target.name, e.target.value)}/>
          </PrimaryCell>
          <PrimaryCell>
            <Paragraph>Elev</Paragraph>
            <CellInput placeholder="Fyll i namn på elev" name="student" onInput={e => this.setInput(e.target.name, e.target.value)}/>
          </PrimaryCell>
        </Wrapper>
        <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            as={Link}
            to={{
              pathname: '/situations',
              state: {data: this.props.data, personalInfo: this.state.inputData}
            }}
          >
            Gå vidare →
          </Button>
        </div>
      </StyledSheet>
    )
  }
}

export default PersonalInfo

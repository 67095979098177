import { createGlobalStyle } from 'styled-components/macro'
import styledNormalize from 'styled-normalize'

require('typeface-source-sans-pro')

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  td, th {
    border: 1px solid black;
  }

  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  textarea:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  textarea:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
  body {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: normal;
    background-color: #f6f2ef;
    color: #283c46;
    position: relative;
    text-size-adjust: 100%;
    min-height: 700px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    :focus, :hover, :active {
      text-decoration: underline;
    }
  }
  p {
    margin-block-start: 0;
  }
  .sticky {
    position: fixed;
    top: 0;
    margin-left: 780px;
  }
  @page :first {
    margin-top: 0;
  }
  @page {
    margin: 2.5cm 0;
  }
`
export default GlobalStyles

import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div({
  backgroundColor: '#faf8f7',
  borderRadius: 15,
  display: 'flex',
  flexWrap: 'wrap',
  margin: '25px 0 40px',
})
const PrimaryCell = styled.div({
  flexGrow: 1,
  width: '50%',
  height: 70,
  border: '0px dashed #ddd',
  padding: 10,
  fontWeight: 600,
})

function PrimaryInfo(props) {
  return (
    <Wrapper>
      <PrimaryCell css={{ borderBottomWidth: 1, borderRightWidth: 1 }}>
        Datum <br/>
      {props.data.date}
      </PrimaryCell>
      <PrimaryCell css={{ borderBottomWidth: 1 }}>
        Skola <br/>
        {props.data.school}
      </PrimaryCell>
      <PrimaryCell css={{ borderRightWidth: 1 }}>
        Lärare <br/>
        {props.data.teacher}
      </PrimaryCell>
      <PrimaryCell>
        Elev <br/>
        {props.data.student}
      </PrimaryCell>
    </Wrapper>
  )
}

export default PrimaryInfo
